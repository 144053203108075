@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .font-helvetica {
    font-family: Helvetica Neue, Helvetica;
  }

  .chad-background {
    background-image: url("./resources/imgs/riggs-fam.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

::-webkit-scrollbar-thumb {
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-clip: padding-box;
  background-color: rgba(92, 92, 92, 0.984);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}
